import type { NextPage } from "next";
import React from "react";
import { Center, ContainerWhite } from "../components/basic";

const Error: NextPage = () => (
	<ContainerWhite>
		<Center>
			<h1>404 - Stránka nenalezena</h1>
		</Center>
	</ContainerWhite>
);

export default Error;
